body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0px;
  padding: 0px;
  
}


html {
  
  background: #ffff;
}

.navigation {
  display: block;
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 70px;
}

.artistNameA img {
  max-width: 150px;
  margin-left: -33px;
}

.soldLabel {
  background: #fe0005;
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 3px;
  margin-right: 4px;
}

.headerContainer {
  float: left;
  /*background: #f3f3f3;*/
  height: 100%;
  width: 280px;
  position: fixed;
  border-right: 1px solid #e4e4e4;
  background-color: #fff;
}

.headerContainer div a {
  text-decoration: none;
  color: #1f1f1f;
  /*font-weight: bold;*/
  font-style: normal;
  text-transform: none;
  letter-spacing: 0em;
  font-size: 13px;
}

.langSwitchFr {
  cursor: pointer;
  font-size: 13px;
  color: #1f1f1f;
  /*font-weight: bold;*/
}

.headerContainer .base {
  width: 100%;
  margin: 8px 0px;
}

.headerContainer div a:hover {
  color: #000;
}

.mainLayout {
  margin-left: 330px;
}

.mainLayOutInner {
  padding-top: 20px;
}

.bioBlock {
  font-size: 13px;
  max-width: 720px;
  line-height: 1.5;
  padding-top: 10px;
  margin-right: 20px;
  color: #1f1f1f;
}

.artistName {
  width: 100px;
  /*text-decoration: underline;*/
  cursor: pointer;
}

.artistNameA {
  text-align: left;
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  margin-right: 10px;
}

/* Create four equal columns that sits next to each other */
.column {
  /*
  flex: 25%;
  max-width: 25%;
  */
  flex: 24%;
  max-width: 24%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }

  .pic1M {
    width: 100% !important;
    height: unset !important;
    /* height: calc(100vh - 180px); */
    /* border: 1px solid #e4e4e4; */
}

.imageWraper .arrow1,
.imageWraper .arrow2 {
opacity: 1;
}
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }

  .contact-form {
    margin-right: 10px !important;
}

.bioBlock {
  margin-right: 10px !important;
}

  .imageWraper .arrow1,
  .imageWraper .arrow2 {
  opacity: 1;
}

  .headerContainer {
    float: left;
    width: 100%;
    position: sticky;
    margin-bottom: 30px;
    border-right: 0px;
    background-color: #fff;
}

.pic1M {
  /*width: 100%;*/
  height: unset !important;
  width: 100%;
  /*border: 1px solid #e4e4e4;*/
}

.mainLayout {
  margin-left: 10px;
}

.imageWraper {

  padding-right:10px !important;
  /*height: 100vh;*/
  margin-bottom: 30px;
  width: fit-content;

  /*
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  */
}



  
}




.pic1 {
  /*
  max-height: 370px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  box-sizing: content-box;
  */
}

.pic1 img {
  /*
  max-height: 370px;
  */
  /*border: 1px solid #e4e4e4;*/
}

.picText {
  position: absolute;
  background: #fff;
  opacity: 0;
  padding: 5px;
  font-size: 13px;
  /*font-weight: bold;*/
  line-height: 1.8em;
}

.pic1:hover .picText {
  opacity: 1;
  transition: 0.5s;
}

.pic1:hover img {
  opacity: .9;
}

.pic1M {
  /*width: 100%;*/
  height: calc(100vh - 200px);
  /*border: 1px solid #e4e4e4;*/
}

.contact-form label {
  font-size: 12px;
}

.pic1MDisc {
  max-width: 400px;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 8px;
}

.mainLayOutInner a {
  text-decoration: none;
  color: #000;
}

.imageWraper {

  padding-right:35px;
  /*height: 100vh;*/
  margin-bottom: 30px;
  width: fit-content;

  /*
  margin-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  */
}

.imageWraper:hover .arrow1,
.imageWraper:hover .arrow2 {
  opacity: 1;
}

.arrow1 a, .arrow2 a {
  padding: 5px;
  color: #313131;
}

.arrow1 {
  opacity: 0;
  font-size: 25px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s;
}

.arrow2 {
  opacity: 0;
  font-size: 25px;
  cursor: pointer;
  font-weight: bold;
  float: right;
  transition: 0.5s;
}

.social {
  width: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
}

.social:hover {
  opacity: .8;
}

input[type=text], select,
input[type=hidden], select,
input[type=email], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


input[type=submit] {
  width: 100%;
  background-color: #000000;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  opacity: .8 ;
}

.contact-form {
  max-width: 600px;
  margin-top: 30px;
  margin-right: 40px;
  margin-bottom: 40px;
}